<template>
    <v-card rounded="lg" class="rounded-lg pa-3 pt-0">
        <v-card-title class="px-3 pb-4 pt-2">
            <h2>{{ $t('MissionDetail') }}</h2>
        </v-card-title>

        <v-card-text class="px-3">
            <v-alert v-if="mission && mission.operation_id && mission.operation_id.status && mission.operation_id.status == 'DRAFT'" border='left' dense color="warning" dark text>
                <span v-html="$t('operation_being_configured')"></span>
            </v-alert>
            <div class="configurationContent">
                <div class="block text-center">
                    <div class="pb-3">
                        <div class="configContainer" v-if="mission.name">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('missionName') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0">{{mission.name}}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="mission.sousType">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('type') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0">{{$t(mission.sousType)}}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="mission.config && mission.config.date_start">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('start') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0">{{parseFullDateToFr(mission.config.date_start)}}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="mission.config && mission.config.date_end">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('end') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0">{{parseFullDateToFr(mission.config.date_end)}}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="mission.date_programming">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('date_programming') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0">{{parseFullDateToFr(mission.date_programming)}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="pb-3">
                        <div class="configContainer" v-if="mission.operation_id">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('operation') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0">
                                    <span class="link" @click="openDrawerObject">{{ mission.operation_id.label }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="mission.ope_date_start">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('operation_date_start') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0">
                                    <span class="" >{{ parseFullDateToFr(mission.ope_date_start) }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="mission.ope_date_end">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('operation_date_end') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0">
                                    <span class="" >{{ parseFullDateToFr(mission.ope_date_end) }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="mission.customer">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('customer') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0">
                                    <span>{{ mission.customer }}</span>
                                </p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="mission.entities">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ mission.entities.length > 1 ? $t('entities') : $t('entity') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0">
                                    <span>{{ mission.entities.map(function(entity) { return entity.text; }).join(', ') }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div v-if="mission.config">
                        <div class="configContainer" v-if="mission.config.sousType == 'campCALL' && mission.config.contact_number >= 0">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('numberOfCards') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0">{{mission.config.contact_number}}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="mission.config.segmentation && mission.config.segmentation.text">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('segmentation') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0">{{mission.config.segmentation.text}}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="mission.config.box && mission.config.box.text">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('box') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0">{{mission.config.box.text}}</p>
                            </div>
                        </div>
                        <div v-if="mission.config.entrypointsCallIn">
                            <div class="configContainer" v-for="(entrypoint, index) in mission.config.entrypointsCallIn">
                                <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('entrypoint') }} {{index + 1}} :</b></p></div>
                                <div class="configLabel text-left">
                                    <p class="mb-0">{{$t('calling_number')}} : {{entrypoint.text}}</p>
                                    <p class="mb-0">{{$t('redirect_number')}} : {{entrypoint.redirect}}</p>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import dateManipulation from '@/mixins/dateManipulation';
import GenericDataService from '@/services/GenericDataService';

export default {
    name: "detailComponent",
    mixins:[dateManipulation],
    props:['mission'],
    data() {
        return {}
    },
    created() {},
    mounted() {},
    watch: {},
    methods: {
        openDrawerObject(){
            GenericDataService.getData('/mission/getOperationExcerpt?id=' + this.mission.id).then((response) => {
                this.$store.dispatch("auth/SET_OPEN_OPERATION_DRAWER", { value: !this.$store.state.auth.openOperationDrawer, operation: this.mission.operation_id, excerpt : response.data.data});
            });
        },
    }
}
</script>

<style lang="scss">
    .iconEdit {
        position:absolute;
        top:20px;
        right:24px;
    }
    .configurationContent {
        color:rgba(0,0,0,.87);
        .configContainer {
            display:flex;
            width:100%;
            .configLabel {
                width: 50%;
            }
        }
    }
</style>