<template>
    <div class="organization-component">
        <div class="parents">
            <div class="parent-item mb-1" v-for="parent in organizations.parents" :key="parent.id">
                <v-card elevation="0" outlined @click="goTo(parent.id)">
                    <v-card-title class="pa-1 pl-4">
                       <h6>{{parent.label}}</h6>
                    </v-card-title>
                </v-card>
            </div>
        </div>
        <div class="main-item">
            <v-icon small>$return_d</v-icon>
            <v-card elevation="0">
                <v-card-title class="pa-1 pl-4">
                <h6>{{organizations.label}}</h6>
                </v-card-title>
            </v-card>
        </div>
       
        <div class="children">
            <div class="child-item mt-1" v-for="children in organizations.children" :key="children.id">
                <v-icon small>$return_d</v-icon>
                <v-card elevation="0" outlined @click="goTo(children.id)">
                    <v-card-title class="pa-1 pl-4">
                       <h6>{{children.label}}</h6>
                    </v-card-title>
                </v-card>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Organization",
    props: ["organizations"],
    methods: {
        goTo: function(id){
            this.$router.push({name : "EntityDetail", params: {entity_id: id}})
        }
    },
}
</script>
<style lang="scss">
    .organization-component {
        h6 {
            text-transform: uppercase;
        }
        .main-item {
            display:flex;
            align-items: center;
            position:relative;
            left:15px;
            .v-card{
                width:70%;
                margin-left: 12px;
                border: 2px solid var(--v-primary-base);
                color: var(--v-primary-base);
            }
            .v-icon {
                transform: rotate(90deg)
            }
        }
        .children {
            position:relative;
            left: 55px;
            flex-direction: column;
        }
        .parents {
            position:relative;
            .parent-item {
                display:flex;
                align-items: center;
            }
            .v-card{
                width:70%;
                color:grey ;
            }
            
        }
        .child-item {
            display:flex;
            align-items: center;
            .v-card{
                width:70%;
                margin-left: 12px;
                color:grey ;
            }
            .v-icon {
                transform: rotate(90deg)
            }
        }
    }
</style>