<template>
  <v-card rounded="lg" class="pa-3">
    <v-card-title class="pb-2 pt-0">
       <h2>{{$t('informations')}}</h2>
      <v-spacer></v-spacer>
      <v-btn v-if="$func.hasRight('entity/update')" depressed text class="square" @click="getForm(formUrl)">
        <v-icon dark small>$pencil_l</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div>
        <div class="pb-3 d-table ml-auto mr-auto">
          <div v-if="entity.email" class="mb-3">
            <v-icon color="black" small class="mr-2">$at_l</v-icon>
            <a :href="'mailto:' + entity.email">{{ entity.email }}</a>
          </div>
          <div v-if="entity.phone && entity.phone.number" class="mb-3">
            <v-icon color="black" small class="mr-2">$phone_l</v-icon>
            <a :href="'tel:' + entity.phone.number">{{
              entity.phone.number
            }}</a>
          </div>
          <div
            v-if="
              entity.social_raison ||
              entity.adress_1 ||
              entity.adress_2 ||
              entity.adress_3 ||
              entity.postal_code ||
              entity.city ||
              entity.country
            "
            class="adress mb-3"
          >
            <v-icon color="black" small class="mr-2">$map_l</v-icon>
            <div>
              <div v-if="entity.social_raison">
                <strong>{{ entity.social_raison }}</strong>
              </div>
              <div v-if="entity.adress_1">{{ entity.adress_1 }}</div>
              <div v-if="entity.adress_2">{{ entity.adress_2 }}</div>
              <div v-if="entity.adress_3">{{ entity.adress_3 }}</div>
              <div v-if="entity.postal_code || entity.city">
                <span v-if="entity.postal_code">{{ entity.postal_code }} </span>
                <span v-if="entity.city">{{ entity.city }}</span>
              </div>
              <div v-if="entity.country">{{ entity.country }}</div>
            </div>
          </div>
        </div>
        <v-divider></v-divider>
        <table class="pt-3 ml-auto mr-auto">
          <tr v-if="entity.type_id">
            <td class="text-right">
              <b>{{$t('type')}} : </b>
            </td>
            <td class="text-left" v-html="entity.type_id.label"></td>
          </tr>
          <tr v-if="entity.brand_ids && entity.brand_ids.length > 0">
            <td class="text-right">
              <b>{{$t('brands')}} : </b>
            </td>
            <td class="text-left">{{ entity.brand_ids.map(e => e.label).join(", ") }}</td>
          </tr>
          <tr v-if="entity.rcs">
            <td class="text-right">
              <b>{{$t('rcsNumber')}} : </b>
            </td>
            <td class="text-left" v-html="entity.rcs"></td>
          </tr>
          <tr v-if="entity.main_contact">
            <td class="text-right">
              <b>{{$t('entitymain_contacttitle')}} : </b>
            </td>
            <td class="text-left" v-html="entity.main_contact.firstname + ' ' + entity.main_contact.name"></td>
          </tr>
          <tr v-if="entity.email_reclamation">
            <td class="text-right">
              <b>{{$t('reclamationEmail')}} : </b>
            </td>
            <td class="text-left" v-html="entity.email_reclamation"></td>
          </tr>
        </table>
      </div>
    </v-card-text>
    <FormDialogComponent
      v-if="form"
      ref="componentForm"
      :type="'edit'"
      :form="form"
      :action="'SET_DATA'"
    />
  </v-card>
</template>
<script>
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import getForm from '@/mixins/mixins'

export default {
  name: "informationComponent",
  components: {
    FormDialogComponent,
  },
  mixins:[getForm],
  data() {
    return {
      formUrl: "/entity/" + this.$route.params.entity_id + "/getForm",
      form: null,
      apiUrl: "/entity/" + this.$route.params.entity_id + "/get",
      mutation: "entities/setEntityData"
    };
  },
  methods: {},
  computed: {
    entity: function () {
      return this.$store.state.entities.entity;
    },
  },
  watch: {
    $route(to, from) {
      this.formUrl = "/entity/" + this.$route.params.entity_id + "/getForm",
      this.apiUrl = "/entity/" + this.$route.params.entity_id + "/get"
    }
  }
};
</script>
<style lang="scss">
.adress {
  display: flex;
  .v-icon {
    margin-top: 3px;
  }
}
</style>
