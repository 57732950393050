var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"titleIdComponent"},[_c('h2',{staticClass:"mb-0",staticStyle:{"width":"100%","align-items":"center"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.table.name))+" "),_c('v-spacer'),(_vm.data && _vm.data.actions && _vm.data.actions.global)?_c('div',[_vm._l((_vm.data.actions.global.menus),function(menu,index){return _c('v-menu',{key:'menu_'+index,staticClass:"action-buttons ml-3",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"bg-gradient",attrs:{"depressed":"","ripple":""}},'v-btn',attrs,false),on),[(menu.icon)?_c('v-icon',{attrs:{"left":"","dark":"","small":""}},[_vm._v("$"+_vm._s(menu.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(menu.title))+" ")],1)]}}],null,true)},[_c('v-list',_vm._l((menu.items),function(button,index){return _c('v-list-item',{key:'btnMenu_'+index,attrs:{"dense":"","link":""},on:{"click":function($event){$event.stopPropagation();return _vm.actionForm(button)}}},[_c('v-list-item-title',{staticClass:"action-menu"},[(button.icon)?_c('v-icon',{staticClass:"ml-3",attrs:{"small":""}},[_vm._v("$"+_vm._s(button.icon))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(button.title))+" ")],1)],1)}),1)],1)}),(_vm.data.actions.global.multi && _vm.data.actions.global.multi.length > 0)?_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"color":"primary","depressed":"","disabled":_vm.selected.length == 0,"ripple":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('multiActionsDatatableLabel'))+" "),_c('v-icon',{staticClass:"ml-3"},[_vm._v(" $expand ")])],1)]}}],null,false,3375486746)},[_c('v-list',_vm._l((_vm.data.actions.global.multi),function(action,index){return _c('v-list-item',{key:index,attrs:{"dense":"","link":""}},[_c('v-list-item-title',{staticClass:"action-menu",on:{"click":function($event){$event.stopPropagation();action.action == 'form' ? _vm.actionForm(action, _vm.selected) : _vm.getLink('multi', action, _vm.selected)}}},[(action.icon)?_c('v-icon',{staticClass:"mr-3",attrs:{"small":""}},[_vm._v(" $"+_vm._s(action.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(action.title))+" ")],1)],1)}),1)],1):_vm._e()],2):_vm._e()],1)]),(_vm.data && _vm.data.headers && _vm.data.data)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.data.headers),function(header){return _c('th',{key:header.value,staticClass:"text-left"},[_vm._v(" "+_vm._s(header.type !== "actions" ? _vm.$t(header.text) : '')+" ")])}),0)]),_c('tbody',_vm._l((_vm.data.data),function(item,key){return _c('tr',{key:key,class:item._main_contact ? 'mainContact' : ''},_vm._l((_vm.data.headers),function(header){return _c('td',{key:header.value},[_c('span',[(header.type == 'actions')?_c('div',{key:header.value,staticClass:"icons-datatable"},_vm._l((_vm.singleAction),function(link,idLink){return _c('span',{key:idLink},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({key:idLink,staticClass:"square",attrs:{"depressed":"","text":"","id":idLink},on:{"click":function($event){$event.stopPropagation();return _vm.getLink('single', link, item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" $"+_vm._s(link.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(link.title)))])])],1)}),0):(header.type == 'object')?_c('div',[(item[header.value].label)?_c('v-chip',{staticClass:"text-uppercase mr-2",attrs:{"color":"info","small":"","outlined":""}},[_vm._v(" "+_vm._s(item[header.value].label)+" ")]):_vm._e()],1):(header.type == 'array_phone')?_c('div',_vm._l((item[header.value]),function(phone,index){return _c('div',{key:index,staticClass:"pointer",on:{"click":function($event){$event.stopPropagation();return _vm.dialPhone(phone.number)}}},[(phone.type)?_c('v-chip',{staticClass:"text-uppercase mr-2",attrs:{"color":"primary","x-small":"","outlined":""}},[_vm._v(_vm._s(phone.type))]):_vm._e(),_vm._v(" "+_vm._s(phone.number)+" ")],1)}),0):(header.type == 'identity')?_c('div',[_c('v-avatar',{attrs:{"size":"36px","color":"primary"}},[_c('v-img',{attrs:{"src":_vm.api+'/user/'+item.id+'/getAvatar?token='+_vm.$store.state.auth.idToken+'&nocache=true'}})],1),_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(item.firstname)+" "+_vm._s(item.name))])],1):_c('span',[_vm._v(_vm._s(item[header.value]))])])])}),0)}),0)]},proxy:true}],null,false,658629304)}):_vm._e(),(_vm.form)?_c('FormDialogComponent',{ref:"componentForm",attrs:{"form":_vm.form,"action":'SET_DATA'},on:{"refreshData":_vm.readDataFromAPI}}):_vm._e(),_c('ConfirmDialogComponent',{ref:"confirmDelete"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }