<template>
    <v-card rounded="lg" class="mb-6" style="height:100%;">
        <v-card-title>
            <h3 class="black--text" style="text-transform:none;">{{$t('rdvObjective')}}</h3>
        </v-card-title>
        <v-card-text class="d-flex flex-column justify-space-between" style="height: calc(100% - 64px);">
            <DonutComponent v-if="data && data.hasOwnProperty('data') && graphData" :dataSet="graphData" :percent_total="data.percent_total" :percent_color="data.percent_color" :disableTooltip="true" style="height:200px;"/>
            <v-alert v-else class="mb-0 mt-12 " border="left" color="info" text>
                {{ $t("NoObjectiveSetted") }}
            </v-alert>
            <p class="mb-0 text-center" v-if="data.label"><b>{{ data.label }}</b></p>
            <v-btn depressed block @click="getObjectiveForm" color="primary" class="mt-8 flex-grow-0" v-if="$func.hasRight('retroplanning/set_objective')">{{ $t('modifyobjective') }}</v-btn>
        </v-card-text>
    </v-card>
</template>
<script>
import DonutComponent from '@/components/graphs/DonutComponent'
import getForm from "@/mixins/mixins";
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";

export default {
    name:'rdvObjective',
    props:['data'],
    mixins: [getForm],
    components:{DonutComponent},
    data() {
        return {
            graphData: {},
        }
    },
    mounted() {
        this.graphDataFct();
    },
    created() {
        this.graphDataFct();
    },
    watch: {
        data(newVal){
            if(newVal)
                this.graphDataFct();
        }
    },
    methods: {
        getObjectiveForm(){
            this.$emit('editCallStats');
        },
        graphDataFct(){
            if(!this.data)
                return;
            let graphData = {}
            graphData.datasets = [];

            let dataset = {};
            dataset.label = "";
            graphData.total = 0;
            if(this.data.hasOwnProperty("percent_total"))
                graphData.total = this.data.percent_total;
            else if(this.data.data)
                Object.values(this.data.data).map(function (obj) { return obj.count }).forEach((value, index) => {
                    if(value)
                        graphData.total += parseInt(value);
                })
            
           

            dataset.data = (graphData.total == 0 ? [100] : Object.values(this.data.data).map(function (obj) { return obj.count }));
            dataset.backgroundColor =  (graphData.total == 0 ? ['#DDD'] : Object.values(this.data.data).map(function (obj) { return obj.color }));
            graphData.datasets.push(dataset);
            //graphData.labels = (graphData.total == 0 ? [this.$t('nodata')] : this.labels);

            this.graphData = graphData;
        },
    },
}
</script>
<style lang="scss">
    
</style>