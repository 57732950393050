<template>
  <v-card rounded="lg" v-if="organizations" class="pa-3">
    <v-card-title class="pb-2 pt-0">
      <h2>{{$t('organisation')}}</h2>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text style="max-height:500px; overflow:hidden; overflow-y:auto;">
      <div>
        <Organization :organizations="organizations" />
      </div>
    </v-card-text>
    <FormDialogComponent
      v-if="form"
      ref="componentForm"
      :type="'edit'"
      :form="form"
      :action="'SET_DATA'"
    />
  </v-card>
</template>
<script>
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
import Organization from "@/components/Organization.vue";
import getForm from '@/mixins/mixins'

export default {
  name: "organisationComponent",
  mixins:[getForm],
  components: {
    Organization,
    FormDialogComponent
  },
  data() {
    return {
      formUrl: "/entity/getFormAddToGroup",
      form: null,
      apiUrl: "/entity/get?id=" + this.$route.params.entity_id,
      mutation: "entities/setEntityData"
      //orgId: 1,
      // organizations: [
      //   {
      //     id: 1,
      //     label: "Groupe Legrand",
      //     children: [
      //       {
      //         id: 2,
      //         label: "Plaque BMW Mini",
      //         clickable: true,
      //       },
      //       {
      //         id: 3,
      //         label: "Plaque OPEL",
      //         clickable: true,
      //       },
      //       {
      //         id: 4,
      //         label: "District Ouest",
      //         clickable: true,
      //       },
      //     ],
      //     parents: [
      //       {
      //         id: 5,
      //         label: "Fake Parent",
      //       },
      //       {
      //         id: 6,
      //         label: "Fake Parent",
      //       },
      //     ],
      //   },
      //   {
      //     id: 2,
      //     label: "Plaque BMW Mini",
      //     children: [
      //       {
      //         id: 7,
      //         label: "BMW",
      //       },
      //       {
      //         id: 8,
      //         label: "MINI",
      //       },
      //     ],
      //     parents: [
      //       {
      //         id: 1,
      //         label: "Groupe Legrand",
      //         clickable: true,
      //       },
      //     ],
      //   },
      //   {
      //     id: 3,
      //     label: "Plaque OPEL",
      //     children: [],
      //     parents: [
      //       {
      //         id: 1,
      //         label: "Groupe Legrand",
      //         clickable: true,
      //       },
      //     ],
      //   },
      //   {
      //     id: 4,
      //     label: "District Ouest",
      //     children: [
      //       {
      //         id: 9,
      //         label: "Ouest Child",
      //       },
      //       {
      //         id: 10,
      //         label: "Ouest Child 2",
      //       },
      //     ],
      //     parents: [
      //       {
      //         id: 1,
      //         label: "Groupe Legrand",
      //         clickable: true,
      //       },
      //     ],
      //   },
      // ],
    };
  },
  computed: {
    organizations: function() {
      return this.$store.state.entities.entity.organisation
    },
    // mainOrg: function () {
    //   return this.organizations.find((e) => e.id == this.orgId);
    // },
  },
  methods: {
    // changeMainOrg: function (id) {
    //   this.orgId = id;
    // },
  },
};
</script>
<style lang="scss">
</style>
