<template>
    <v-card rounded="lg" class="mb-6" style="height:100%;">
        <v-card-title>
            <h3 class="black--text" style="text-transform:none;">{{$t('treatment')}}</h3>
        </v-card-title>
        <v-card-text>
            <div v-for="(item, index) in data" class="mt-4">
                <template v-if="index != 'global'">
                    <div class="d-flex align-center justify-space-between mb-1">
                        <span>{{ $t(index)  }}</span>
                        <span>{{ item+"/"+data.global.value }}</span>
                    </div>
                    <v-progress-linear
                    color="success"
                    height="10"
                    rounded
                    :value="getPercent(item, data.global.value)"
                    ></v-progress-linear>
                </template>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: 'treatmentStats',
    props: ['data'],
    data() {
        return {
            
        }
    },
    methods: {
        getPercent(value, total){
            return (value * 100) / total;
        }
    },
}
</script>