<template>
    <v-card rounded="lg" class="rounded-lg pa-3 pt-0">
        <v-card-title class="px-3 pb-4 pt-2">
            <h2>{{ $t('MissionPlace') }}</h2>
        </v-card-title>
        <v-card-text class="px-3 pb-0">
            <v-divider></v-divider>
            <v-card class="no-shadow">
                <v-card-text class="text-adress">
                    <b class="mb-2">{{ place.config.name }}</b>
                    <p v-if="place.config.adress" class="mb-0" v-html="place.config.adress"></p>
                    <p class="mb-0" v-if="place.config.city">
                        <span v-if="place.config.zip && place.config.zip != ''">{{ place.config.zip }} - </span>{{ place.config.city }}
                    </p>
                    <p v-if="place.config.country" class="mb-0" v-html="place.config.country"></p>
                </v-card-text>
            </v-card>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "detailComponent",
    props:['mission', 'place'],
    data() {
        return {}
    },
    created() {},
    mounted() {},
    watch: {},
    methods: {},
}
</script>

<style lang="scss">
</style>