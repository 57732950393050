<template>
    <v-container fluid class="pa-6">
        <v-row>
            <v-col cols="4">
                <DetailComponent :mission="mission" class="mb-4" />
                <PlaceComponent v-if="mission.config && mission.config.place" :mission="mission" :place="mission.config.place" class="mb-4" />
                <v-row class="" v-if='mission.config && mission.config.canal && (["TEL"]).includes(mission.config.canal)'>
                    <v-col cols="6" v-if="$func.hasRight('retroplanning/get_objective')">
                        <rdvObjective :data="statCampCall" :key="'camp_call_' + statsCallKey"/>
                    </v-col>
                    <v-col cols="6">
                        <treatmentStats :data="statTreatment" :key="'camp_treatment_' + statsCallKey"/>
                    </v-col>
                    <v-col cols="6" v-if="mission.config.prestataire_type === 'VENDORS'">
                        <campDonutComponent :title="'statsRdvVendor'" :data="statRdvVendor" :key="'camp_rdvVendor_' + statsCallKey"/>
                    </v-col>
                    <v-col cols="6">
                        <campDonutComponent :title="'statsFailMotif'" :data="statFailMotive" :key="'camp_fail_' + statsCallKey"/>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="8">
                <v-card class="mb-4" v-for="table in tables" :key="table.name + key">
                    <v-card-title class="titleIdComponent">
                        <h2 class="mb-0" style="width:100%; align-items:center;">
                            {{ $t(table.name) }}
                            <v-spacer></v-spacer>
                        </h2>
                    </v-card-title>
                    <DatatableV2 :displayTitle="false" :flat="true" :forceDisablePagination="true" :forceDisableSort="true" :shiftButton="true" :url="table.apiUrl" />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import DetailComponent from '@/modules/missions/missionsComponents/detailComponent';
import PlaceComponent from '@/modules/missions/missionsComponents/placeComponent';
import TableComponent from "@/modules/missions/missionsComponents/tableComponent";
import DatatableV2 from '@/components/DatatableV2';
import GenericDataService from '@/services/GenericDataService'
import rdvObjective from '@/modules/operations/operationsComponents/rdvObjective.vue';
import treatmentStats from '@/modules/operations/operationsComponents/treatmentStats.vue';
import campDonutComponent from '@/modules/operations/operationsComponents/campDonutComponent.vue';

export default {
    name: "MissionDetailDashboard",
    components: { DetailComponent, PlaceComponent, TableComponent, DatatableV2, rdvObjective, treatmentStats, campDonutComponent },
    data() {
        return {
            tables: [],
            key: 0,
            statsCallKey:0,
            statCampCall: {},
            statTreatment: {},
            statRdvVendor: {},
            statFailMotive: {},
        };
    },
    created() {},
    methods: {},
    watch: {
        mission(val){
            if(val && val.type == 'CAMP_CALL') {
                GenericDataService.getData('/mission/getCampCallStats?id=' + this.$route.params.mission_id).then((response) => {
                    this.statCampCall = response.data.data.statsRdv;
                    this.statTreatment = response.data.data.statsTreatment;
                    this.statRdvVendor = response.data.data.statsRdvVendor;
                    this.statFailMotive = response.data.data.statsMotifFail;
                    this.statsCallKey++;
                }); 
            }

            if(!val.id)
                this.tables = [];

            let tablesGenerated = [
                {
                    "name": "contractor",
                    "apiUrl": "/mission/getContractor?id=" + val.id,
                },
                {
                    "name": "customers",
                    "apiUrl": "/mission/getCustomers?id=" + val.id,
                }
            ];

            if(val.config.staff_qty) {
                tablesGenerated.push({
                    "name": "assignedToTheMission",
                    "apiUrl": "/mission/getAssigned?id=" + val.id
                })
            }
            this.tables = tablesGenerated;
            this.key++;
        }
    },
    computed: {
        mission: function(){ 
            return this.$store.state.missions.mission
        }
    },
};
</script>

<style lang="scss">
</style>
