<template>
  <v-card rounded="lg" class="pa-3">
    <v-card-title class="pb-2 pt-0">
      <h2>{{$t('schedules')}}</h2>
      <v-spacer></v-spacer>
      <v-btn v-if="$func.hasRight('entity/update')" depressed text class="square" @click="dialog = true">
        <v-icon dark small>$pencil_l</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div>
        <table class="ml-auto mr-auto">
          <tr v-for="(days, index) in hoursReversed" :key="'day_'+index">
            <td class="text-right pb-3"><b class="mr-3">{{$t(daysArray[index-1])}}</b></td>
            <td class="text-left pb-3" v-if="days.length > 0">
              <v-chip label small v-for="(hour, index) in days" :key="index" class="mr-2 my-1">
                  <span>{{hour}}</span>
              </v-chip>
            </td>
            <td class="text-left pb-3" v-else>
              <v-chip label small class="mr-2">
                  <span>{{$t('closeLabel')}}</span>
              </v-chip>
            </td>
          </tr>
        </table>
      </div>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      fullscreen
      transition="dialog-bottom-transition"
      scrollable
      persistent
      @keydown.esc="cancel"
      content-class="vdialogPlanning"
    >
        <v-card tile>
          <v-toolbar flat dark class="bg-gradient">
                <v-btn
                    depressed
                    color="primary"
                    class="square mr-2"
                    @click.native="cancel"
                >
                    <v-icon dark small>$prev</v-icon>
                </v-btn>
            
            <v-toolbar-title>{{ $t('entityHours') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn outlined class="mr-3" @click="cancel" color="white">Annuler</v-btn>
            <v-btn outlined color="white" @click="recordHours">Enregistrer</v-btn>
          </v-toolbar>
          <v-card-text>
              <Planning ref="planning" :type="planning.type"  :genericUnavailable="genericUnavailable"
                  :isMine="false"
                  :creneau="60"
                  :operations="[]"
                  :appointements="[]"
                  :entityId="entityId"
                  @addItem="addItem"
                  @removeUnavailable="removeUnavailable"
                  @removeAllUnavailable="removeAllUnavailable"
                  @addAllUnavailable="addAllUnavailable"  
                  mode="popup"
                />
          </v-card-text>
        </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import Planning from '@/components/planning/Planning'
import planningMixins from '@/mixins/planningMixins'
import dateManipulation from '@/mixins/dateManipulation'
import GenericDataService from '@/services/GenericDataService'
export default {
  name: "scheduleComponent",
  components: {Planning},
  mixins:[planningMixins, dateManipulation],
  props: ['hours', 'hoursReversed', 'entityId'],
  data() {
    return {
        publicPath:process.env.BASE_URL,
        dialog: false,
        planningKey:0,
        planning: {
          type : 'INDISPO_CONCESSION',
        },
        genericUnavailable: [
           
        ],
    }
  },
  created() {
    
  },
  computed: {
      daysArray: function(){
          return ['mondayLabel', 'tuesdayLabel', 'wednesdayLabel', 'thursdayLabel', 'fridayLabel', 'saturdayLabel', 'sundayLabel']
      }
  },
  methods: {
      recordHours : function(){
        let id = this.$route.params.entity_id
        GenericDataService.postData('/entity/'+id+'/setHours', this.genericUnavailable).then((response) =>{
          this.$store.dispatch('entities/SET_HOURS', this.genericUnavailable).then((e) => {
            this.$store.dispatch("SET_DATA", {
              url: "/entity/get?id=" + this.$route.params.entity_id,
              mutation: "entities/setEntityData",
              data:{},
            }).then((e) => {
              this.dialog = false
            })
          })
        })
      },
      cancel: function(){
        this.genericUnavailable = this._.cloneDeep(this.hours)  
        this.dialog = false
      },
      addAllUnavailable(day){
            //let unavailable = this.genericUnavailable.findIndex((e) => e.day == day)
            let fullSlot = {}
            fullSlot.start = "00:00"
            fullSlot.end = "24:00",
            fullSlot.type = "hebdomadaire",
            fullSlot.source ="entity"
            fullSlot.day = day
            this.genericUnavailable.push(fullSlot)
        },
        removeAllUnavailable(day){
            let unavailableArray = this.genericUnavailable.filter((e) => e.day == day)
            unavailableArray.forEach((e) => {
              let unavailableIndex = this.genericUnavailable.findIndex((e) => e.day == day)
              this.genericUnavailable.splice(unavailableIndex, 1)
            })
            //unavailable.slots = [];
        },
        //Ajout d'une indispo
        addItem:function(event, unavailableData) {
            //let slot = this.genericUnavailable.find((e) => e.day == unavailableData.day)
            let item = this._.cloneDeep(unavailableData);
            item.start = this.parseFloatToHour(this.parseHourToFloat(unavailableData.start))
            item.end = this.parseFloatToHour(this.parseHourToFloat(unavailableData.end))
            //check si une indispo est présente avant et apres
            let itemBefore = this.genericUnavailable.find(
                (e) => (e.day == unavailableData.day && 
                          (
                              this.parseHourToFloat(e.end) == this.parseHourToFloat(item.start) || 
                              (this.parseHourToFloat(e.start) < this.parseHourToFloat(item.start) 
                                  && this.parseHourToFloat(item.start) <= this.parseHourToFloat(e.end) 
                                  && this.parseHourToFloat(item.end) > this.parseHourToFloat(e.end))
                          )
                        )
            );
            let itemAfter = this.genericUnavailable.find(
              (e) => (e.day == unavailableData.day && 
                        (
                            this.parseHourToFloat(e.start) == this.parseHourToFloat(item.end) || 
                            (this.parseHourToFloat(item.start) < this.parseHourToFloat(e.start) 
                              && this.parseHourToFloat(item.end) >= this.parseHourToFloat(e.start)
                              && this.parseHourToFloat(e.end) > this.parseHourToFloat(item.end))
                        )
                      )
                );
            let itemUnder = this.genericUnavailable.filter((e) => (e.day == unavailableData.day && this.parseHourToFloat(e.start) >= this.parseHourToFloat(item.start) && this.parseHourToFloat(e.end) <= this.parseHourToFloat(item.end) && e.end != "00:00"))
            // //si les deux existent on redéfini les horaires du premier et on supprime le deuxieme
            if(itemBefore && itemAfter){
                let itemAfterIndex = this.genericUnavailable.findIndex((e) => e.day == unavailableData.day && e.start == item.end);
                itemBefore.end = itemAfter.end
                this.genericUnavailable.splice(itemAfterIndex, 1)
            }else if(itemBefore){   // si un element est présent avant alors on le fusionne avec les nouvelles data
                itemBefore.end = item.end
            } else if (itemAfter) { // si un element est présent après alors on le fusionne avec les nouvelles data
                itemAfter.start = item.start
            } else if(itemUnder){
                for (let index = 0; index < itemUnder.length; index++) {
                    let itemUnderIndex = this.genericUnavailable.findIndex((e) => e.day == unavailableData.day && e.start == itemUnder[index].start && e.end == itemUnder[index].end)   
                    this.genericUnavailable.splice(itemUnderIndex, 1)   
                }
                this.genericUnavailable.push(item);
            } else { // sinon on le push
                this.genericUnavailable.push(item);
            }   
        },
        //Suppression d'une indispo
        removeUnavailable(event, slot){
            let dayItem = this.genericUnavailable.findIndex((e) => e.day == slot.day && e.start == slot.start)
            //let slotIndex = dayItem.slots.findIndex((e) => e.start == slot.start)
            this.genericUnavailable.splice(dayItem, 1)
        },
  },
  watch: {
    hours: function(newVal){
         this.genericUnavailable = this._.cloneDeep(this.hours)     
    }
  },
};
</script>
<style lang="scss">
  
</style>
